import React from "react"
import { MenuItem } from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';
import Menu from "../index"
import { Link } from "gatsby"

const useStyles = makeStyles({
  navLink: {
    color: "white",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    fontWeight: "200",
    "&:hover": {
      color: "white",
    },
  },
})

export default function JetMenu({ isOpen, anchorEl, handleClose }) {
  const classes = useStyles()

  return (
    <Menu isOpen={isOpen} anchorEl={anchorEl} handleClose={handleClose}>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/jet/getting-started">
          Getting Started
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/jet/manual-jobs">
          Manual Jobs
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/jet/email-notifications">
          Email Notifications
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/jet/webhooks">
          Webhook Notifications
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/jet/webhook-reference">
          Jet Event Webhook Examples
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
   
      <Link className={classes.navLink} to="/jet/job-configuration">
        Advanced Job Configuration
      </Link>   
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/signiant-platform/roles">
        User Roles on the Signiant Platform
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/signiant-platform/media-engine-webhook-actions">
        Media Engine Webhook Actions
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/jet/api-documentation">
          API Documentation
        </Link>
      </MenuItem>
    </Menu>
  )
}
